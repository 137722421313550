<template>
	<main class="container">
		<div class="flex space-between mb-40">
			<div>
				<h1 class="mb-10">Teldupostar</h1>

				<p class="color-grey">Yvirlit yvir teldupostar</p>
			</div>

			<div class="flex align-center">
				<div class="mr-10 relative search-input small">
					<input
						type="text"
						ref="searchInput"
						class="focus:background-light-grey-100"
						v-model="searchString"
						@keydown.esc="searchString = ''"
						placeholder="Leita"
					/>

					<div class="icon-search"></div>
				</div>

				<router-link class="btn-add" :to="{ name: 'ActionCreate' }"></router-link>
			</div>
		</div>

		<div v-if="loading" class="flex justify-center">
			<i class="fas fa-spinner fa-spin fa-2x color-blue"></i>
		</div>

		<table v-if="!loading">
			<thead>
				<th>Heiti</th>

				<th>Stovnað</th>

				<th>&nbsp;</th>
			</thead>

			<tbody>
				<tr v-for="(action, index) in filteredActions" :key="`action-${index}`">
					<td class="cursor-pointer" @click.prevent="editAction(action.id)">{{ action.title }}</td>

					<td>{{ new Date(action.createdAt).format('%d/%m/%y') }}</td>

					<td class="right">
						<i class="far fa-trash-alt cursor-pointer mr-20" @click="deleteAction(action.id)"></i>

						<i class="far fa-edit cursor-pointer" @click.stop="editAction(action.id)"></i>
					</td>
				</tr>
			</tbody>
		</table>
	</main>
</template>

<script>
import axios from 'axios';

export default {
	name: 'Actions',

	data() {
		return {
			loading: true,

			searchString: '',
			actions: [],
		};
	},

	computed: {
		filteredActions() {
			return this.actions.filter((action) => {
				const fields = ['title', 'message', 'subject', 'receivers'];

				for (const field of fields) {
					if (action[field].indexOf(this.searchString) > -1) {
						return true;
					}
				}

				return false;
			});
		},
	},

	mounted() {
		axios
			.get(`${process.env.VUE_APP_TERMINAL_API_URL}/actions`)
			.then((response) => {
				this.actions = response.data;

				this.$nextTick(() => {
					this.$refs.searchInput.focus();
				});
			})
			.catch((error) => {
				console.log('Error fetching actions', error);
			})
			.finally(() => {
				this.loading = false;
			});
	},

	methods: {
		editAction(actionId) {
			this.$router.push({ name: 'ActionEdit', params: { id: actionId } });
		},

		deleteAction(actionId) {
			if (!confirm('Ert tú vís/ur?')) {
				return;
			}

			axios
				.delete(`${process.env.VUE_APP_TERMINAL_API_URL}/actions/${actionId}`)
				.then(() => {
					const index = this.actions.findIndex((item) => item.id == actionId);

					this.actions.splice(index, 1);
				})
				.catch((error) => {
					console.log('Error fetching actions', error);
				});
		},
	},
};
</script>
